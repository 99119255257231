import { useMemo } from "react";

import { get } from "lodash";
import { isUndefined } from "lodash/lang";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { useColumnOrder } from "react-table/src/plugin-hooks/useColumnOrder";
import { useFlexLayout } from "react-table/src/plugin-hooks/useFlexLayout";
import { useResizeColumns } from "react-table/src/plugin-hooks/useResizeColumns";
import { useRowSelect } from "react-table/src/plugin-hooks/useRowSelect";
import { lifecycle, withState } from "recompose";
import { compose } from "redux";
import { propTypes } from "redux-form";

import { useUserHeaderStatePersist } from "@dpdgroupuk/mydpd-app";
import { Button, DndTable, withOverlay } from "@dpdgroupuk/mydpd-ui";
import { withTrackProps } from "@dpdgroupuk/react-event-tracker";

import Step from "~/components/Step";
import { DEFAULT_ADDRESS_COLUMN_ORDER } from "~/constants/columns";
import {
  Fields,
  FilterFields,
  SEARCH_AND_SELECT_HELP,
} from "~/constants/forms";
import * as S from "~/constants/strings";
import help from "~/models/info";
import { ADDRESSES } from "~/router";
import {
  getQueryFilters,
  getQueryPagination,
  INITIAL_PAGE_QUERY,
  stringifyQuery,
} from "~/utils/query";

import FilterForm from "./components/FilterForm/FilterForm";
import styles from "./SearchAndSelect.module.scss";

const SearchAndSelect = ({
  title,
  helpModalTitle,
  filterOptionsList,
  isInvalidRow,
  useTypeCheckbox,
  typeCheckboxLabel,
  ...props
}) => {
  const { searchPage, searchPageSize, filters } = useMemo(
    () => ({
      ...getQueryPagination(props.location),
      filters: {
        [FilterFields.SEARCH_CRITERIA_FIELD]: Fields.SHORT_NAME,
        ...getQueryFilters(props.location),
      },
    }),
    [props.location]
  );

  const defaultColumn = useMemo(
    () => ({
      minWidth: 105,
      width: 200,
      maxWidth: 400,
    }),
    []
  );

  const unselectAll = useMemo(
    () => isUndefined(get(props, "match.params.id")),
    [get(props, "match.params.id")]
  );

  const hiddenColumns = useMemo(() =>
    // use default visible columns
    props.columns.reduce((acc, column) => {
      if (!DEFAULT_ADDRESS_COLUMN_ORDER.includes(column.accessor)) {
        acc.push(column.accessor);
      }
      return acc;
    }, [])
  );

  return (
    <Step
      classes={{
        container: styles.container,
      }}
      step={1}
      title={title}
      help={help[SEARCH_AND_SELECT_HELP]}
      helpModalTitle={helpModalTitle}
    >
      <FilterForm
        initialValues={filters}
        filterOptionsList={filterOptionsList}
        useTypeCheckbox={useTypeCheckbox}
        typeCheckboxLabel={typeCheckboxLabel}
        onFieldEntry={props.onFieldEntry}
        onTypeChange={props.onTypeChange}
        onSearchFieldChange={props.onSearchFieldChange}
        onSearchTextChange={props.onSearchTextChange}
      />
      <DndTable
        data={props.records}
        columns={props.columns}
        classes={{
          container: styles.tableContainer,
          table: styles.table,
          invalidRow: styles.invalidTableRow,
          popover: styles.popover,
        }}
        tableHooks={[
          useFlexLayout,
          useRowSelect,
          useResizeColumns,
          useColumnOrder,
          useUserHeaderStatePersist,
        ]}
        initialState={{
          hiddenColumns,
          columnOrder: DEFAULT_ADDRESS_COLUMN_ORDER,
          selectedRowIds: props.selectedRowIds,
          storageKey: "addressColumns",
        }}
        isVisiblePopover
        defaultColumn={defaultColumn}
        onClickRow={props.onClickRow}
        selectOnlyOneRow={true}
        unselectAll={unselectAll}
        isInvalidRow={isInvalidRow}
      >
        <DndTable.DndPaginator
          page={parseInt(searchPage)}
          totalCount={props.totalResults}
          pageSize={parseInt(searchPageSize)}
          onPrevious={props.onPrevious}
          onNext={props.onNext}
          onFirst={props.onFirst}
          onLast={props.onLast}
        />
      </DndTable>
      {!props.isUserReadonlyPermissions && (
        <div className={styles.footer}>
          <Button onClick={props.onClickDeleteAll} variant="danger">
            {S.DELETE_ALL}
          </Button>
          <div>
            <Button variant="dark" onClick={props.onClickNew}>
              {S.NEW}
            </Button>
            <Button
              className={styles.lastChild}
              variant="dark"
              onClick={props.openImportModal}
            >
              {S.IMPORT}
            </Button>
          </div>
        </div>
      )}
    </Step>
  );
};

SearchAndSelect.propTypes = {
  onFieldEntry: PropTypes.func, // only for analytics
  onSearchChange: PropTypes.func,
  toggleImportModal: PropTypes.func,
  openImportModal: PropTypes.func,
  closeImportModal: PropTypes.func,
  showImportModal: PropTypes.bool,
  onImport: PropTypes.func,
  onClickDeleteAll: PropTypes.func,
  onClickNew: PropTypes.func,
  countries: PropTypes.array,
  requiredFields: PropTypes.object,
  useTypeCheckbox: PropTypes.bool,
  typeCheckboxLabel: PropTypes.string,
  ...propTypes,
};

export default compose(
  withRouter,
  withOverlay,
  withState(
    "selectedRowIds",
    "setSelectedRowIds",
    ({ initialSelectedRowIds }) => initialSelectedRowIds || {}
  ),
  connect(
    () => ({}),
    (dispatch, { history, location, setSelectedRowIds, onClickRow }) => {
      const getModifiedQueryParams = newQuery => {
        const page = getQueryPagination(location);

        const query = getQueryFilters(location);

        return stringifyQuery({
          ...page,
          ...query,
          ...newQuery,
        });
      };

      const changeFilter = newQuery => {
        history.push({
          pathname: ADDRESSES,
          search: getModifiedQueryParams(newQuery),
        });
      };

      const changeFilterAndResetPagination = newQuery => {
        changeFilter({
          ...newQuery,
          searchPage: INITIAL_PAGE_QUERY.searchPage,
          searchPageSize: INITIAL_PAGE_QUERY.searchPageSize,
        });
      };

      const onNextOrPrev = searchPage => {
        changeFilter({ searchPage });
      };

      return {
        onSearchFieldChange: (e, value) => {
          changeFilterAndResetPagination({
            [FilterFields.SEARCH_CRITERIA_FIELD]: value,
          });
        },
        onSearchTextChange: (e, value) => {
          changeFilterAndResetPagination({
            [FilterFields.SEARCH_CRITERIA_VALUE]: value,
          });
        },
        onTypeChange: (e, value) => {
          changeFilterAndResetPagination({ [Fields.TYPE]: value });
        },
        onNext: onNextOrPrev,
        onPrevious: onNextOrPrev,
        onFirst: onNextOrPrev,
        onLast: onNextOrPrev,
        onClickRow: async (e, row) => {
          setSelectedRowIds({});
          await onClickRow?.(row);
          row.toggleRowSelected(row.id, true);
        },
      };
    }
  ),
  lifecycle({
    componentDidMount() {
      this.props.searchAddressBooks();
    },
  }),
  withTrackProps(props => props.trackProps || {})
)(SearchAndSelect);
