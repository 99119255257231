import classNames from "classnames";
import { Col, Container, Row } from "react-bootstrap";
import { compose } from "redux";
import { Field, propTypes, reduxForm } from "redux-form";

import { Form } from "@dpdgroupuk/mydpd-ui";

import ReduxifiedCheckboxWithLabel from "~/components/ReduxifiedCheckboxWithLabel";
import {
  BOOK_SEARCH_FORM,
  Fields,
  FIELDS_LENGTH,
  FilterFields,
} from "~/constants/forms";
import { SEARCH, SEARCH_LABEL, UPPERCASE } from "~/constants/strings";
import searchAddressSchema from "~/models/validators/searchAddressSchema";
import debounce from "~/utils/debounce";
import createValidator from "~/utils/joiReduxForm";

import styles from "./FilterForm.module.scss";

const getHelperText = (field, filterOptionsList) =>
  `${SEARCH} ${filterOptionsList
    .find(({ value }) => value === field)
    ?.label.toLowerCase()}`;

const FilterForm = props => {
  const { initialValues } = props;

  return (
    <>
      <Container fluid className={classNames(styles.accountContainer)}>
        <Row className={styles.row}>
          <Col md={5}>
            <Field
              label={SEARCH_LABEL}
              component={Form.MaterialDropdown}
              name={FilterFields.SEARCH_CRITERIA_FIELD}
              values={props.filterOptionsList}
              onBlur={props.onFieldEntry}
              textTransform={UPPERCASE}
              onChange={debounce((e, value) =>
                props.onSearchFieldChange(e, value)
              )}
            />
          </Col>
          <Col md={7}>
            <Field
              classes={{
                input: styles.searchInput,
              }}
              maxLength={FIELDS_LENGTH.default}
              component={Form.MaterialTextInput}
              label={SEARCH}
              name={FilterFields.SEARCH_CRITERIA_VALUE}
              onBlur={props.onFieldEntry}
              helperText={getHelperText(
                initialValues.searchCriteriaField,
                props.filterOptionsList
              )}
              type="search"
              onChange={debounce((e, value) =>
                props.onSearchTextChange(e, value)
              )}
            />
          </Col>
          {props.useTypeCheckbox && (
            <Col md={12} className={classNames(styles.col)}>
              <Field
                type="checkbox"
                name={Fields.TYPE}
                label={props.typeCheckboxLabel}
                onChange={props.onTypeChange}
                onBlur={props.onFieldEntry}
                component={ReduxifiedCheckboxWithLabel}
                format={v => v && parseInt(v)}
                normalize={v => (v ? 1 : 0)}
                className={styles.hideAddressesCheckbox}
              />
            </Col>
          )}
        </Row>
      </Container>
    </>
  );
};

FilterForm.propTypes = {
  ...propTypes,
};

export default compose(
  reduxForm({
    form: BOOK_SEARCH_FORM,
    enableReinitialize: true,
    destroyOnUnmount: false,
    validate: createValidator(searchAddressSchema),
  })
)(FilterForm);
