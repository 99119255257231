import PropTypes from "prop-types";

import { CheckboxWithLabel } from "@dpdgroupuk/mydpd-ui";

export const ReduxifiedCheckboxWithLabel = ({ input, ...inputProps }) => (
  <CheckboxWithLabel {...input} {...inputProps} />
);

ReduxifiedCheckboxWithLabel.propTypes = {
  label: PropTypes.string,
  input: PropTypes.object,
};

export default ReduxifiedCheckboxWithLabel;
