import { createAsyncAction } from "@dpdgroupuk/redux-action-creator";

import { addressBookApi } from "~/apis";
import { Fields } from "~/constants/forms";

import ActionTypes from "./actionTypes";
import { preparingImportRequest } from "./models";

export const searchAddressBooks = createAsyncAction(
  async (query, fetchOptions) => {
    const { data } = await addressBookApi.fetchAddressBooks(
      { ...query, searchSort: Fields.SHORT_NAME },
      fetchOptions
    );
    return data;
  },
  ActionTypes.SEARCH
);

export const deleteAddressBookById = createAsyncAction(
  async (addressBookId, query, fetchOptions) => {
    await addressBookApi.deleteAddressBookById(
      addressBookId,
      query,
      fetchOptions
    );
    return {
      addressBookId,
      ...query,
    };
  },
  ActionTypes.DELETE
);

export const fetchAddressBookById = createAsyncAction(
  async (addressBookId, type) => {
    const { data } = await addressBookApi.fetchAddressBookById(
      addressBookId,
      type
    );
    return data;
  },
  ActionTypes.FETCH
);

export const deleteAllAddressBooks = createAsyncAction(
  addressBookApi.deleteAddressBook,
  ActionTypes.DELETE_ALL
);

export const importAddressBook = createAsyncAction(async params => {
  const { formData, query } = preparingImportRequest(params);
  return addressBookApi.importAddressBook(formData, query);
}, ActionTypes.IMPORT);

export const clearImportErrors = () => ({
  type: ActionTypes.CLEAR_IMPORT_ERRORS,
});

export const updateAddressBook = createAsyncAction(
  async (addressBookId, body, options) => {
    await addressBookApi.updateAddressBook(
      addressBookId,
      { addressBookType: body.addressBookType },
      body,
      options
    );
    return {
      addressBookId,
      ...body,
    };
  },
  ActionTypes.UPDATE
);

export const createAddressBook = createAsyncAction(async (body, options) => {
  const { data } = await addressBookApi.insertAddressBook(body, options);
  return {
    addressBookId: data.addressbookId,
    ...body,
  };
}, ActionTypes.CREATE);
