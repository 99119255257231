export const DPD_EXTERNAL_LINK =
  process.env.REACT_APP_EXTERNAL_DOMAIN || "http://localhost:3000";

export const CUSTOM_DOMAIN = {
  DPD_UK_DEV: "dpduk.dev",
  DPD_CO_UK: "dpd.co.uk",
  DPD_LOCAL_CO_UK: "dpdlocal.co.uk",
};

export const CUSTOM_DOMAIN_LIST = Object.values(CUSTOM_DOMAIN);
