export const upperCaseNormalize = v => v && v.toUpperCase();

export const phoneNumberNormalize = value => {
  if (!value) {
    return value;
  }

  let phoneValue = value?.replace(/[^+\d]/g, "");
  const plusCharacterIndex = phoneValue?.lastIndexOf("+");

  if (plusCharacterIndex !== -1 && plusCharacterIndex !== 0) {
    phoneValue =
      phoneValue?.slice(0, plusCharacterIndex) +
      phoneValue?.slice(plusCharacterIndex + 1);
  }

  return phoneValue.substring(0, 15);
};
