import { useMemo } from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Field, propTypes } from "redux-form";

import { FormControl, Form as MyDpdUiForm } from "@dpdgroupuk/mydpd-ui";

import PhoneInput from "~/components/PhoneInput";
import ReduxifiedCheckboxWithLabel from "~/components/ReduxifiedCheckboxWithLabel";
import { AddressBookEntity, FIELDS_LENGTH } from "~/constants/forms";
import * as S from "~/constants/strings";
import PostcodeFinderInput from "~/features/PostcodeFinder";
import { ADDRESS_BOOK_TYPES } from "~/models/enum";
import { joinStringsWithComma } from "~/utils/string";

import CountriesAutocomplete from "../CountriesAutocomplete/CountriesAutocomplete";
import styles from "./Form.module.scss";

const Form = props => {
  const {
    isReadonly,
    isDisabled,
    isDisabledAddressType,
    addressType,
    addressBookId,
    onPostcodeSelectionChange,
    countryCode,
    requiredFields,
    selectedCountry,
    onCountryChange,
  } = props;
  const isCountryGB = useMemo(() => countryCode === S.GB, [countryCode]);

  return (
    <MyDpdUiForm autocomplete="off" className={classNames(styles.container)}>
      <Container fluid className="p-0">
        <Row>
          <Col sm={6} className={styles.column}>
            <Field
              readonly={isReadonly}
              disabled={isDisabled}
              component={MyDpdUiForm.MaterialTextInput}
              label={S.SHORT_NAME}
              name={AddressBookEntity.SHORT_NAME}
              maxLength={FIELDS_LENGTH.shortName}
              helperText={S.ENTER_SHORT_NAME}
              onBlur={props.onFieldEntry}
              required={requiredFields[AddressBookEntity.SHORT_NAME]}
            />
            <Field
              component={CountriesAutocomplete}
              id={AddressBookEntity.ADDRESS.COUNTRY_CODE}
              name={AddressBookEntity.ADDRESS.COUNTRY_CODE}
              label={S.DESTINATION_COUNTRY}
              helperText={S.ENTER_OR_SELECT_DESTINATION_COUNTRY}
              onCountryChange={onCountryChange}
              countries={props.countries}
              required={requiredFields[AddressBookEntity.ADDRESS.COUNTRY_CODE]}
              disabled={isDisabled}
              readonly={isReadonly}
              selectedCountry={selectedCountry}
            />
            <Field
              key={addressBookId}
              readonly={isReadonly}
              disabled={isDisabled}
              component={PostcodeFinderInput}
              label={S.POSTAL_ZIP_CODE}
              name={AddressBookEntity.ADDRESS.POSTCODE}
              maxLength={FIELDS_LENGTH.postcode}
              helperText={S.DESTINATION}
              onBlur={props.onFieldEntry}
              id={AddressBookEntity.ADDRESS.POSTCODE}
              labelKey={option => option.postcode}
              optionLabelMapper={option =>
                joinStringsWithComma([
                  option.organisation,
                  option.property,
                  option.street,
                  option.town,
                ])
              }
              onSelectionChange={onPostcodeSelectionChange}
              withAutocomplete={isCountryGB}
              showFindButton={isCountryGB}
              required={requiredFields[AddressBookEntity.ADDRESS.POSTCODE]}
            />
            <Field
              readonly={isReadonly}
              disabled={isDisabled}
              component={MyDpdUiForm.MaterialTextInput}
              label={S.ORGANISATION_NAME}
              name={AddressBookEntity.ADDRESS.ORGANISATION}
              maxLength={FIELDS_LENGTH.default}
              helperText={S.MAX_35_CHARACTERS}
              onBlur={props.onFieldEntry}
            />
            <Field
              readonly={isReadonly}
              disabled={isDisabled}
              component={MyDpdUiForm.MaterialTextInput}
              label={S.ADDRESS_LINE_1}
              name={AddressBookEntity.ADDRESS.STREET}
              maxLength={FIELDS_LENGTH.default}
              helperText={S.MAX_35_CHARACTERS}
              onBlur={props.onFieldEntry}
              required={requiredFields[AddressBookEntity.ADDRESS.STREET]}
            />
            <Field
              readonly={isReadonly}
              disabled={isDisabled}
              component={MyDpdUiForm.MaterialTextInput}
              label={S.ADDRESS_LINE_2}
              name={AddressBookEntity.ADDRESS.LOCALITY}
              maxLength={FIELDS_LENGTH.default}
              helperText={S.MAX_35_CHARACTERS}
              onBlur={props.onFieldEntry}
            />
            <Field
              readonly={isReadonly}
              disabled={isDisabled}
              component={MyDpdUiForm.MaterialTextInput}
              label={S.CITY}
              name={AddressBookEntity.ADDRESS.TOWN}
              maxLength={FIELDS_LENGTH.default}
              helperText={S.MAX_35_CHARACTERS}
              onBlur={props.onFieldEntry}
              required={requiredFields[AddressBookEntity.ADDRESS.TOWN]}
            />
            <Field
              readonly={isReadonly}
              disabled={isDisabled}
              component={MyDpdUiForm.MaterialTextInput}
              label={S.COUNTY_STATE}
              name={AddressBookEntity.ADDRESS.COUNTY}
              maxLength={FIELDS_LENGTH.default}
              helperText={S.MAX_35_CHARACTERS}
              onBlur={props.onFieldEntry}
            />
            <Field
              readonly={isReadonly}
              disabled={isDisabled}
              component={MyDpdUiForm.MaterialTextInput}
              label={S.CONTACT_NAME}
              name={AddressBookEntity.CONTACT_DETAILS.CONTACT_NAME}
              maxLength={FIELDS_LENGTH.default}
              helperText={S.MAX_35_CHARACTERS}
              onBlur={props.onFieldEntry}
              required={
                requiredFields[AddressBookEntity.CONTACT_DETAILS.CONTACT_NAME]
              }
            />
            <PhoneInput
              readonly={isReadonly}
              disabled={isDisabled}
              component={MyDpdUiForm.MaterialTextInput}
              label={S.CONTACT_TELEPHONE}
              name={AddressBookEntity.CONTACT_DETAILS.TELEPHONE}
              maxLength={FIELDS_LENGTH.telephone}
              helperText={S.MAX_15_CHARACTERS}
              onBlur={props.onFieldEntry}
              required={
                requiredFields[AddressBookEntity.CONTACT_DETAILS.TELEPHONE]
              }
            />
          </Col>
          <Col sm={6} className={styles.column}>
            <Field
              readonly={isReadonly}
              disabled={isReadonly ? false : isDisabledAddressType}
              component={MyDpdUiForm.MaterialDropdown}
              label={S.ADDRESS_TYPE}
              name={AddressBookEntity.ADDRESS_TYPE}
              onBlur={props.onFieldEntry}
              onChange={props.onAddressTypeChange}
              values={[
                {
                  label: "DELIVERY",
                  value: ADDRESS_BOOK_TYPES.DELIVERY,
                },
                {
                  label: "RETURN",
                  value: ADDRESS_BOOK_TYPES.RETURN,
                },
              ]}
              hideInitial={true}
              textTransform={S.UPPERCASE}
              required={requiredFields[AddressBookEntity.ADDRESS_TYPE]}
            />
            {addressType === ADDRESS_BOOK_TYPES.DELIVERY && (
              <>
                <Field
                  readonly={isReadonly}
                  disabled={isDisabled}
                  component={MyDpdUiForm.MaterialTextInput}
                  label={S.NOTIFICATION_EMAIL}
                  name={AddressBookEntity.NOTIFICATION_DETAILS.EMAIL}
                  maxLength={FIELDS_LENGTH.email}
                  helperText={S.MAX_100_CHARACTERS}
                  onBlur={props.onFieldEntry}
                />
                <PhoneInput
                  readonly={isReadonly}
                  disabled={isDisabled}
                  component={MyDpdUiForm.MaterialTextInput}
                  label={S.MOBILE_NOTIFICATION_TEXT}
                  name={AddressBookEntity.NOTIFICATION_DETAILS.MOBILE}
                  maxLength={FIELDS_LENGTH.telephone}
                  helperText={S.MAX_15_CHARACTERS}
                  onBlur={props.onFieldEntry}
                />
              </>
            )}
            <Field
              readonly={isReadonly}
              disabled={isDisabled}
              multiline
              component={MyDpdUiForm.MaterialTextInput}
              classes={{
                input: styles.textarea,
              }}
              rows={4}
              label={S.DELIVERY_INFORMATION}
              name={AddressBookEntity.DELIVERY_INSTRUCTION}
              maxLength={FIELDS_LENGTH.instructions}
              helperText={S.MAX_50_CHARACTERS}
              onBlur={props.onFieldEntry}
            />
            <Field
              readonly={isReadonly}
              disabled={isDisabled}
              component={MyDpdUiForm.MaterialTextInput}
              label={S.YOUR_REFERENCE_1}
              name={AddressBookEntity.SHIPPING_REF_1}
              maxLength={FIELDS_LENGTH.reference}
              helperText={S.MAX_25_CHARACTERS}
              onBlur={props.onFieldEntry}
            />
            <Field
              readonly={isReadonly}
              disabled={isDisabled}
              component={MyDpdUiForm.MaterialTextInput}
              label={S.YOUR_REFERENCE_2}
              name={AddressBookEntity.SHIPPING_REF_2}
              maxLength={FIELDS_LENGTH.reference}
              helperText={S.MAX_25_CHARACTERS}
              onBlur={props.onFieldEntry}
            />
            <Field
              readonly={isReadonly}
              disabled={isDisabled}
              component={MyDpdUiForm.MaterialTextInput}
              label={S.YOUR_REFERENCE_3}
              name={AddressBookEntity.SHIPPING_REF_3}
              maxLength={FIELDS_LENGTH.reference}
              helperText={S.MAX_25_CHARACTERS}
              onBlur={props.onFieldEntry}
            />
            <Field
              component={FormControl.Input}
              label={S.EORI_NUMBER}
              name={AddressBookEntity.EORI_NUMBER}
              onBlur={props.onFieldEntry}
              maxLength={FIELDS_LENGTH.vatNumber}
              helperText={S.MAX_45_CHARACTERS}
              disabled={isDisabled}
              readonly={isReadonly}
            />
            <Field
              readonly={isReadonly}
              disabled={isDisabled}
              component={MyDpdUiForm.MaterialTextInput}
              label={S.VAT_NUMBER}
              name={AddressBookEntity.VAT_NUMBER}
              maxLength={FIELDS_LENGTH.vatNumber}
              helperText={S.MAX_45_CHARACTERS}
              onBlur={props.onFieldEntry}
            />
            <Field
              component={FormControl.Input}
              label={S.PID_NUMBER}
              name={AddressBookEntity.PID_NUMBER}
              onBlur={props.onFieldEntry}
              maxLength={FIELDS_LENGTH.vatNumber}
              helperText={S.MAX_45_CHARACTERS}
              disabled={isDisabled}
              readonly={isReadonly}
            />
            {addressType === ADDRESS_BOOK_TYPES.RETURN && (
              <Field
                readonly={isReadonly}
                disabled={isDisabled}
                className={styles.defaultCheckbox}
                type="checkbox"
                name={AddressBookEntity.IS_DEFAULT}
                label={S.DEFAULT_ADDRESS}
                onChange={props.onDefaultAddressChange}
                onBlur={props.onFieldEntry}
                component={ReduxifiedCheckboxWithLabel}
              />
            )}
          </Col>
        </Row>
      </Container>
    </MyDpdUiForm>
  );
};

Form.propTypes = {
  isDisabled: PropTypes.bool,
  isReadonly: PropTypes.bool,
  ...propTypes,
};

export default Form;
